/* Startpage Banner */

    document.addEventListener('DOMContentLoaded', () => {

        // Event-Listener für den Theme-Wechsel
     const stopMovement = document.getElementById('stopMovement');
    const resumeMovement = document.getElementById('resumeMovement');
    const checkbox = document.getElementById('checkbox');
    const startpageBanner = document.getElementById('startpagebanner'); // Das Element, das überwacht wird

    const stopAnimations = () => {
        movementStopped = true;
        clearInterval(starInterval);

        const stars = document.querySelectorAll('.star');
        stars.forEach(star => {
            star.style.animationPlayState = 'paused';
        });

        const eggContainer = document.querySelector('.eggcontainer');
        if (eggContainer) {
            eggContainer.style.animationPlayState = 'paused';
        }

        stopMovement.classList.add('hidden');
        resumeMovement.classList.remove('hidden');
    };

    const resumeAnimations = () => {
        if (userStopped) return; // Manuelle Pause priorisieren
        movementStopped = false;
        generateStars();

        const stars = document.querySelectorAll('.star');
        stars.forEach(star => {
            star.style.animationPlayState = 'running';
        });

        const eggContainer = document.querySelector('.eggcontainer');
        if (eggContainer) {
            eggContainer.style.animationPlayState = 'running';
        }

        stopMovement.classList.remove('hidden');
        resumeMovement.classList.add('hidden');
    };

        // Beobachter für den Startpage-Bereich (weniger als 30% sichtbar -> Pause, mehr als 30% sichtbar -> Fortsetzen)
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && entry.intersectionRatio >= 0.2) {
                    // Automatisch fortsetzen, wenn sichtbar und nicht manuell pausiert
                    if (!userStopped) {
                        resumeAnimations();
                    }
                } else {
                    // Automatisch pausieren, wenn nicht sichtbar und nicht manuell pausiert
                    if (!userStopped) {
                        stopAnimations();
                    }
                }
            });
        }, { threshold: 0.2 });

        // Startpage-Bereich beobachten
        observer.observe(startpageBanner);

        stopMovement.addEventListener('click', () => {
            userStopped = true; // Manuelles Stoppen aktivieren
            stopAnimations();
        });
        // Setze die Bewegung der Sterne und des eggcontainers fort
        resumeMovement.addEventListener('click', () => {
            userStopped = false; // Manuelle Pause deaktivieren
            resumeAnimations();
        });
    if (checkbox) {
        // Funktion, um die Farbe des SVG-Icons basierend auf dem Dark Mode zu setzen
        const updateSVGColors = () => {
            const isDarkMode = checkbox.checked; // Prüft, ob Dark Mode aktiv ist
            const iconColor = isDarkMode ? '#FFFFFF' : '#000000'; // Iconfarbe ändern

            // Stelle sicher, dass die fill- oder stroke-Farbe gesetzt wird
            stopMovement.setAttribute('fill', iconColor);
            resumeMovement.setAttribute('stroke', iconColor);
            resumeMovement.querySelectorAll('path, circle').forEach((element) => {
                element.setAttribute('stroke', iconColor); // Alle stroke-Eigenschaften auf die neue Farbe setzen
            });
        };
        // Stelle sicher, dass der Zoom-Slider funktioniert (falls vorhanden)
        const zoomSlider = document.getElementById('zoomSlider');
        if (zoomSlider) {
            zoomSlider.addEventListener('input', (event) => {
                const zoom = parseFloat(event.target.value); // Zoom vom Slider übernehmen
                setZoom(zoom); // Setze den Zoom, wenn du eine entsprechende Funktion hast
            });
        } else {
            console.error('Zoom-Slider nicht gefunden!');
        }

        // Initiale Farbe einstellen
        updateSVGColors();

        // Farben aktualisieren, wenn die Checkbox geändert wird
        checkbox.addEventListener('change', updateSVGColors);
    } else {
        console.error('Checkbox Element nicht gefunden');
    }





    const starContainer = document.getElementById('stars-container');
    const starCountSlider = document.getElementById('star-count-slider');
    let movementStopped = false;
    let userStopped = false;
    let starInterval;
    let starsPerBatch = Number(starCountSlider.value); // Startwert aus Slider
    let activeStars = []; // Liste für die aktiven Sterne

    // Funktion, um die Farben der Sterne je nach Modus zu setzen
    function adjustStarColors() {
        const isDarkMode = document.documentElement.classList.contains('dark');
        const stars = document.querySelectorAll('.star');

        stars.forEach(star => {
            star.classList.remove('star-light', 'star-dark');
            if (isDarkMode) {
                star.classList.add('star-light'); // Weiße Sterne im Dunkelmodus
            } else {
                star.classList.add('star-dark'); // Schwarze Sterne im normalen Modus
            }
        });
    }


    if (checkbox) {
        checkbox.addEventListener('change', () => {
            const isDarkMode = checkbox.checked;
            if (isDarkMode) {
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
            }
            adjustStarColors();  // Aktualisiere die Sternfarben nach der Änderung
            setBackgroundColor(isDarkMode);
        });
    } else {
        console.error('Checkbox Element nicht gefunden');
    }
    function setBackgroundColor(isDarkMode) {
        const backgroundColor = isDarkMode ? 'rgb(17, 24, 39)' : 'rgb(255, 255, 255)';
        document.body.style.backgroundColor = backgroundColor;
    }
    // Initiale Themenanpassung
    const isDarkMode = document.documentElement.classList.contains('dark');
    adjustStarColors();  // Setze die Sternfarben beim Initialisieren
    setBackgroundColor(isDarkMode);  // Setze den Hintergrund
    generateStars();

    // Funktion, um einen neuen Stern zu erstellen
    function createStar() {
        const star = document.createElement('div');
        star.classList.add('star');

        // Position zufällig setzen
        const xPos = 100 + Math.random() * 20; // 100% bis 120% des Viewports
        const yPos = Math.random() * 100; // 0% bis 100% Höhe

        star.style.left = `${xPos}%`;
        star.style.top = `${yPos}%`;

        // Animationseinstellungen
        const animationDelay = Math.random() * 2 + 's';
        const animationDuration = Math.random() * 2 + 3 + 's';

        star.style.animationDelay = animationDelay;
        star.style.animationDuration = animationDuration;

        // Zum Container hinzufügen
        starContainer.appendChild(star);

        // Farbe direkt anpassen
        adjustStarColors();

        // Stern nach Ende der Animation entfernen
        star.addEventListener('animationend', () => {
            star.remove();
            // Entferne den Stern aus der Liste der aktiven Sterne, wenn er entfernt wird
            activeStars = activeStars.filter(activeStar => activeStar !== star);
        });

        // Stern in der Liste der aktiven Sterne speichern
        activeStars.push(star);
    }

    // Funktion, um eine Anzahl an neuen Sternen zu erstellen
    function createStarsBatch(count) {
        for (let i = 0; i < count; i++) {
            createStar();
        }
    }

    // Funktion, um die Anzahl an Sternen basierend auf dem Sliderwert zu aktualisieren
    function updateStars() {
        const currentStarsCount = activeStars.length;
        const newStarsCount = starsPerBatch;

        // Wenn mehr Sterne benötigt werden, erstelle die Differenz
        if (currentStarsCount < newStarsCount) {
            const starsToCreate = newStarsCount - currentStarsCount;
            createStarsBatch(starsToCreate);
        }

        // Wenn weniger Sterne benötigt werden, entferne die Differenz
        if (currentStarsCount > newStarsCount) {
            const excessStars = currentStarsCount - newStarsCount;
            // Entferne überschüssige Sterne, aber lasse die Animation der alten Sterne laufen
            for (let i = 0; i < excessStars; i++) {
                const starToRemove = activeStars.pop();
                starToRemove.remove();
            }
        }
    }

    // Funktion, um Sterne periodisch zu generieren (nur bei Bewegung)
    function  generateStars () {
        if (!movementStopped) {
            clearInterval(starInterval);
            starInterval = setInterval(() => {
                updateStars();
            }, 1000);
        }
    };


    // Stoppe die Bewegung der Sterne

    // Stoppe die Bewegung der Sterne
    // Stoppe die Bewegung der Sterne und des eggcontainers
    document.getElementById('stopMovement').addEventListener('click', () => {
        movementStopped = true;
        clearInterval(starInterval);

        // Pausiere die Sterne
        const stars = document.querySelectorAll('.star');
        stars.forEach(star => {
            star.style.animationPlayState = 'paused';
        });

        // Pausiere den eggcontainer
        const eggContainer = document.querySelector('.eggcontainer');
        if (eggContainer) {
            eggContainer.style.animationPlayState = 'paused'; // Pausiere Animation
        }
        // Zeige Play-Button, verstecke Pause-Button
        document.getElementById('stopMovement').classList.add('hidden');
        document.getElementById('resumeMovement').classList.remove('hidden');
    });

    // Setze die Bewegung der Sterne und des eggcontainers fort
    document.getElementById('resumeMovement').addEventListener('click', () => {
        userStopped = false; // Benutzer erlaubt jetzt automatische Aktionen
        movementStopped = false;
        generateStars();

        const stars = document.querySelectorAll('.star');
        stars.forEach(star => {
            star.style.animationPlayState = 'running';
        });

        const eggContainer = document.querySelector('.eggcontainer');
        if (eggContainer) {
            eggContainer.style.animationPlayState = 'running';
        }

        stopMovement.classList.remove('hidden');
        resumeMovement.classList.add('hidden');
    });



    // Aktualisiere die Sterneanzahl bei Slideränderung
    starCountSlider.addEventListener('input', (event) => {
        starsPerBatch = Number(event.target.value);
        if (!movementStopped) {
            updateStars();
        }
    });


    // Initialisierung
    adjustStarColors();
    generateStars();






    });





/* Startpage MathCanvas */


/*

document.addEventListener('DOMContentLoaded', function () {
    // Ziel-Element (jetzt mit der ID 'mathCanvasArt')
    const targetElement = document.getElementById('mathCanvasArt');

    // Funktion, um das Element nach oben zu scrollen, wenn es in die Nähe kommt
    function scrollToElementIfClose() {
        const targetRect = targetElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Wenn das Element näher als 200px zum oberen Rand des Viewports kommt und noch nicht gescrollt wurde
        if (targetRect.top <= windowHeight - 200 && !targetElement.classList.contains('scrolled')) {
            // Das Scrollen auf den oberen Rand des Elements
            targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });

            // Markiere das Element als gescrollt, um zu verhindern, dass es erneut gescrollt wird
            targetElement.classList.add('scrolled');
        }
    }

    // Scroll-Event überwachen
    window.addEventListener('scroll', scrollToElementIfClose);

    // Initialer Check beim Laden der Seite
    scrollToElementIfClose();
});
*/



document.addEventListener('DOMContentLoaded', () => {
    const mainDropdownButton = document.getElementById('main-dropdown-button');
const mainDropdownMenu = document.getElementById('main-dropdown-menu');
const subDropdownButton = document.getElementById('sub-dropdown-button');
const subDropdownMenu = document.getElementById('sub-dropdown-menu');
const subCategoryBoxes = document.getElementById('sub-category-boxes');
    const formulaContainer = document.getElementById('formula-container');
    const aValueContainer = document.getElementById('a-value-container');
     let currentScriptTag = null;
    let scriptLoaded = false; // Status des Skriptladens
    let currentSubCategoryValue = ''; // Aktueller Wert der Unterkategorie

    // Canvas entfernen
    const removeCanvas = () => {
console.log('Entferne Canvas');
const canvasElements = document.querySelectorAll('#canvas-container canvas');
canvasElements.forEach(canvas => canvas.remove());
animationCount = 0;

if (window.currentP5Instance) {
    window.currentP5Instance.remove(); // Entferne die p5-Instanz
    window.currentP5Instance = null;
}
scriptLoaded = false; // Setze scriptLoaded zurück, um das Skript neu zu laden
};
    // Skript laden
    const loadScript = (src, callback) => {
if (scriptLoaded) {
    console.log(`Skript bereits geladen: ${src}`);
    callback();
    return;
}

        console.log(`Lade Skript: ${src}`);

        const script = document.createElement('script');
script.src = src; // Kein Cache-Busting
script.onload = () => {
    console.log(`Skript geladen: ${src}`);
    scriptLoaded = true;
    callback();
};
script.onerror = () => console.error(`Fehler beim Laden des Skripts: ${src}`);
document.head.appendChild(script);
};
    // Canvas initialisieren
    const initializeCanvas = (scriptPath, value) => {
// Wenn sich die Subkategorie nicht geändert hat, abbrechen
if (value === currentSubCategoryValue) {
    console.log('Wert hat sich nicht geändert. Keine Aktion erforderlich.');
    return;
}

currentSubCategoryValue = value; // Setze den neuen Wert als aktuellen Wert

removeCanvas(); // Entferne den vorherigen Canvas
loadScript(scriptPath, () => {
    if (window.initializeCanvas) {
        window.initializeCanvas(value); // Rufe die Initialisierungsfunktion auf
    }
});
};
const setupMainDropdownOptions = () => {



const translations = {
de: {
    development: "Entwicklung",
    moebius: "Möbius",
    smiley: "Smiley",
    portal: "Portal",
    static: "Statisch",
    experimental: "Experimentell",
    partialdimension: "Partielle Dimension",
    blossom: "Blüte",
    wave: "Welle",
    circle: "Kreis",
    opera: "Opera",

},
en: {
    development: "Development",
    moebius: "Möbius",
    smiley: "Smiley",
    portal: "Portal",
    static: "Static",
    experimental: "Experimental",


    partialdimension: "Partial Dimension",
    blossom: "Blossom",
    wave: "Wave",
    circle: "Circle",
    opera: "Opera"
}
};


const categories = {
    "portal": translations[currentLocale].portal,

    "moebius": translations[currentLocale].moebius,
    "smiley": translations[currentLocale].smiley,
    "static": translations[currentLocale].static,
    "partialdimension": translations[currentLocale].partialdimension,
    "blossom": translations[currentLocale].blossom,

    "opera": translations[currentLocale].opera,
     "development": translations[currentLocale].development,
    "experimental": translations[currentLocale].experimental
};

// Sicherstellen, dass das Menü zunächst leer ist
mainDropdownMenu.innerHTML = '';
Object.keys(categories).forEach((category, index) => {
        const categoryElement = document.createElement('div');
        categoryElement.textContent = categories[category]; // Use the translated name
        categoryElement.className = 'z-20 rounded text-gray-900 dark:text-white px-4 py-2 cursor-pointer border-2 border-gray-400 dark:border-gray-600 dark:hover:border-white hover:border-2 hover:border-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 focus:bg-gray-700 bg-white dark:bg-gray-800 border-opacity-60 duration-200';

        // Default selected category
        if (index === 0) {
            categoryElement.classList.add('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
            categoryElement.classList.remove('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
            handleMainCategorySelection(category);
        }

        // Add click event
        categoryElement.addEventListener('click', () => {
            const allCategoryElements = mainDropdownMenu.querySelectorAll('div');
            allCategoryElements.forEach(el => {
                el.classList.remove('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
                el.classList.add('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
            });

            // Highlight selected category
            categoryElement.classList.add('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
            categoryElement.classList.remove('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
            handleMainCategorySelection(category);
            mainDropdownMenu.classList.add('hidden'); // Close main menu
        });

        mainDropdownMenu.appendChild(categoryElement);
    });

    // Set default selection
    handleMainCategorySelection("portal");

};
const setupSubCategoryBoxes = (subCategories) => {
subCategoryBoxes.innerHTML = '';

subCategories.forEach((option, index) => {
    const boxElement = document.createElement('div');
    boxElement.className = 'z-20 w-full rounded text-gray-900 dark:text-white px-4 py-2 cursor-pointer border-2 border-gray-400 dark:border-gray-600 dark:hover:border-white hover:border-2 hover:border-gray-400 hover:bg-gray-400 dark:hover:bg-gray-700 focus:bg-gray-700 bg-white dark:bg-gray-800 border-opacity-60 duration-200';
    boxElement.textContent = option.label;
    boxElement.style.minWidth = '15x';
    // Standardmäßig aktiviertes Subkategorie-Element
    if (index === 0) {
        boxElement.classList.add('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
        boxElement.classList.remove('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
        initializeCanvas(option.scriptPath, option.value);
        subDropdownButton.textContent = option.label;
    }

    boxElement.addEventListener('click', () => {
        const allBoxes = subCategoryBoxes.querySelectorAll('div');
        allBoxes.forEach(box => {
            box.classList.remove('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
            box.classList.add('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800'); // Setze die Standard-Hintergrundfarbe und -border für nicht ausgewählte Elemente
        });

        // Aktives Element erhält helle Hintergründe und schwarze Border im Light Mode bzw. weiße Border im Dark Mode
        boxElement.classList.add('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
        boxElement.classList.remove('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
        subDropdownButton.textContent = option.label;

        initializeCanvas(option.scriptPath, option.value);
        subDropdownMenu.classList.add('hidden'); // Schließe das Untermenü nach Auswahl
    });

    subCategoryBoxes.appendChild(boxElement);
});


};




    // Hauptkategorie-Auswahl
    const handleMainCategorySelection = (category) => {


        const translations = {
de: {
    development: "Entwicklung",


    opera: "Opera",



    plain: "Normal",
    transversal: "Transversal",
    transversal2: "Transversal 2",
    moebius: "Möbius",
    smiley: "Smiley",
    portal: "Portal",
    static: "Statisch",
    partialdimension: "Partielle Dimension",
    blossom: "Blüte",
    wave: "Welle",
    circle: "Kreis",
    opera: "Oper",
    experimental: "Experimentell",
    experimental1: "Experimentell 1",
    experimental2: "Experimentell 2",
    axial: "Axial",
    axial2: "Axial 2",
    biaxial: "Biaxial",
    bidirectional: "Bidirektional",
    bidirectional2: "Bidirektional 2",
    bidirectional3: "Bidirektional 3",
    twoD: "2D",
    threeD: "3D",
    regular: "Regulär",
    staticLabel: "Statisch",
     multiplication: "Multiplikation"
},
en: {
    development: "Development",







    partialdimension: "Partial Dimension",
    blossom: "Blossom",
    wave: "Wave",
    circle: "Circle",
    opera: "Opera",

    plain: "Plain",
    transversal: "Transversal",
    transversal2: "Transversal 2",
    moebius: "Möbius",
    smiley: "Smiley",
    portal: "Portal",
    static: "Static",
    partialdimension: "Partial Dimension",
    blossom: "Blossom",
    wave: "Wave",
    circle: "Circle",
    opera: "Opera",
    experimental: "Experimental",
    experimental1: "Experimental 1",
    experimental2: "Experimental 2",
    axial: "Axial",
    axial2: "Axial 2",
    biaxial: "Biaxial",
    bidirectional: "Bidirectional",
    bidirectional2: "Bidirectional 2",
    bidirectional3: "Bidirectional 3",
    twoD: "2D",
    threeD: "3D",
    regular: "Regular",
    staticLabel: "Static",
    multiplication: "Multiplication"
}
};





mainDropdownButton.textContent = translations[currentLocale][category]; // Verwende die Übersetzung hier


const subCategories = {
    "portal": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'portalnew' },
        { label: translations[currentLocale].experimental, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'portal' }
    ],
    "moebius": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusplain' },
    { label: translations[currentLocale].transversal, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiustrans' },
    { label: translations[currentLocale].transversal2, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiustrans2' },
            { label: translations[currentLocale].axial, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusaxial' },
    { label: translations[currentLocale].axial2, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusaxial2' },
    { label: translations[currentLocale].biaxial, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusbiaxial' },
    { label: translations[currentLocale].bidirectional, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusbidi' },
    { label: translations[currentLocale].bidirectional2, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusbidi2' },
    { label: translations[currentLocale].bidirectional3, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusbidi3' },
    { label: translations[currentLocale].experimental1, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusexp' },
    { label: translations[currentLocale].experimental2, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'moebiusexp2' }


    ],
    "smiley": [
        { label: translations[currentLocale].twoD, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'standard' },
        { label: translations[currentLocale].threeD, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'standard3d' }
    ],







    "static": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'static' }
             ],
    // "dimension": [
     //   { label: "translations[currentLocale].plain", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'dimension' }
     //],
     "partialdimension": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'partialdimension' }
     ], "blossom": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'blossom' }
     ],

      "opera": [
        { label: translations[currentLocale].plain, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'opera' }

     ],

     "development": [
       // { label: translations[currentLocale].multiplication, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'multiplication' },
        { label: translations[currentLocale].wave, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'waveslide' },
        { label: translations[currentLocale].staticLabel, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'wavestatic' },
        { label: translations[currentLocale].circle, scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'circle' }


     ],
     "experimental": [
        { label: "1", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp1' },
        { label: "2", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp2' },
        { label: "3", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp3' },
        { label: "4", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp4' },
        { label: "5", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp5' },
        { label: "6", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp6' },
        { label: "7", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp7' },
        { label: "8", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp8' },
        { label: "9", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp9' },
        { label: "10", scriptPath: '/vendor/math/mathCanvasMaster.js', value: 'exp10' }


     ]
};
// Entferne die aktive Klasse von allen Hauptkategorieelementen
const allCategoryElements = mainDropdownMenu.querySelectorAll('div');
allCategoryElements.forEach(el => {
    el.classList.remove('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
    el.classList.add('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800'); // Setze die Standard-Hintergrundfarbe und -border für nicht ausgewählte Elemente
});

// Füge die aktive Klasse zum aktuellen Element hinzu
const activeCategoryElement = [...allCategoryElements].find(el => el.textContent.toLowerCase() === category);
if (activeCategoryElement) {
    activeCategoryElement.classList.add('bg-gray-300', 'dark:bg-gray-500', 'border-black', 'dark:border-white');
    activeCategoryElement.classList.remove('border-gray-400', 'dark:border-gray-600', 'bg-white', 'dark:bg-gray-800');
}

setupSubCategoryBoxes(subCategories[category]);
};

    mainDropdownButton.addEventListener('click', () => {
        mainDropdownMenu.classList.toggle('hidden');
    });

    subDropdownButton.addEventListener('click', () => {
        subDropdownMenu.classList.toggle('hidden');
    });

    document.addEventListener('click', (event) => {
        const isClickInsideMain = mainDropdownButton.contains(event.target) || mainDropdownMenu.contains(event.target);
        const isClickInsideSub = subDropdownButton.contains(event.target) || subDropdownMenu.contains(event.target);

        if (!isClickInsideMain) {
            mainDropdownMenu.classList.add('hidden');
        }
        if (!isClickInsideSub) {
            subDropdownMenu.classList.add('hidden');
        }
    });

    setupMainDropdownOptions();
});
